<template>
  <SceneSetup
    class="sea"
    ref="sceneContainer"
    :pageId="pageId"
    :content="content"
    :scenes="scenes"
    :clickedSphere="clickedSphere"
    :currentSceneIndex="currentSceneIndex"
    :loaded="loaded"
    @onSwipeStart="onSwipeStart"
    @onSwipe="onSwipe"
    @closeDetails="closeDetails"
    @setNewScene="changeSceneFromMenu"
    @toProject="navigateToProject"
    @toggleInteraction="setInteract"
  />
</template>

<script>
import { threeSetup } from "@/mixins/three-setup";
import { Color } from "three";
import SceneSetup from "@/components/UI/SceneSetup.vue";

export default {
  components: { SceneSetup },
  mixins: [threeSetup],
  data() {
    // All the data here should specific to the Mare scenes
    return {
      pageId: "sea",
      scenesConfig: [
        {
          folder: "alghae",
          scale: 32,
          position: { y: -3.5, z: 0.05, x: -0.15 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.5 },
          ringGroupRotation: { x: Math.PI * 0.42 }
        },
        {
          folder: "coral",
          scale: 0.45,
          position: { y: -1, z: -1.25, x: -0.25 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.25 },
          ringGroupRotation: { x: Math.PI * 0.6 }
        }
      ],
      gradientColor: {
        color1: {
          value: new Color("hsl(237, 80%, 90%)")
        },
        color2: {
          value: new Color("hsl(237, 40%, 85%)")
        },
        color3: {
          value: new Color("hsl(230, 80%, 70%)")
        },
        step1: {
          value: 0.0
        },
        step2: {
          value: 0.5
        },
        step3: {
          value: 1.5
        }
      },
      glowOptions: {
        opacity: {
          value: 1.0
        },
        color1: {
          value: new Color("hsl(209, 100%, 60%)")
        },
        color2: {
          value: new Color("hsl(209, 100%, 100%)")
        },
        step1: {
          value: 0.0
        },
        step2: {
          value: 1.5
        }
      },
      ringColor: "hsl(237, 70%, 40%)",
      lightColors: ["hsl(237, 50%, 50%)", "hsl(237, 20%, 50%)"]
    };
  },
  computed: {
    content() {
      return this.$t("sea");
    }
  },
  metaInfo() {
    return this.$t("share").sea;
  },

  created() {
    this.setIndexFromMenu(7);
  },

  methods: {
    setSmallScreenConf() {
      this.scenesConfig = this.scenesConfig.map(conf => {
        if (conf.folder === "alghae") {
          conf.ringGroupRotation = { x: Math.PI * 0.45 };
        }

        return conf;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sea {
  // background: $bg-sea;
}
</style>
